import { Controller } from 'stimulus';

const ACTIVE_CLASS = 'is-active';
const DISPLAYED_CLASS = 'is-displayed';

export default class extends Controller {
  static targets = ['card', 'desktopContent'];

  connect() {
    this.setInitialDesktopVisiblity();
  }

  setInitialDesktopVisiblity = () => {
    if (window.innerWidth >= 768) {
      this.desktopContentTargets[0].classList.add(ACTIVE_CLASS, DISPLAYED_CLASS);
      this.cardTargets[0].classList.add(ACTIVE_CLASS);
    }
  };

  onCardClickHandler = e => {
    const clickedCard = e.target;

    if (!clickedCard.classList.contains(ACTIVE_CLASS)) {
      this.cardTargets.forEach(card => card.classList.remove(ACTIVE_CLASS));
      this.desktopContentTargets.forEach(wrapper =>
        wrapper.classList.remove(ACTIVE_CLASS, DISPLAYED_CLASS)
      );

      const targetOfClickedCard = clickedCard.dataset.section;
      const desktopContentWithThisTarget = document.querySelector(
        `.desktop-content__wrapper[data-section=${targetOfClickedCard}]`
      );

      desktopContentWithThisTarget.classList.add(DISPLAYED_CLASS);
      setTimeout(() => {
        desktopContentWithThisTarget.classList.add(ACTIVE_CLASS);
      }, 10);
      clickedCard.classList.add(ACTIVE_CLASS);
    } else {
      if (window.innerWidth <= 768) {
        clickedCard.classList.remove(ACTIVE_CLASS);
      }
    }
  };
}
