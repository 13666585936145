import { Controller } from 'stimulus';

const ACTIVE_CLASS = 'is-active';

export default class extends Controller {
  cards = Array.from(document.querySelectorAll('.services-section__card'));

  contentWrappers = Array.from(document.querySelectorAll('.content__copy'));

  connect() {
    this.addListeners();
    this.setInitialDesktopVisiblity();
  }

  disconnect() {
    this.removeListeners();
  }

  addListeners() {
    this.cards.forEach(card => card.addEventListener('click', this.onCardClickHandler));
  }

  removeListeners() {
    this.cards.forEach(card => card.removeEventListener('click', this.onCardClickHandler));
  }

  setInitialDesktopVisiblity = () => {
    if (window.innerWidth >= 768) {
      this.contentWrappers[0].classList.add(ACTIVE_CLASS);
      this.cards[0].classList.add(ACTIVE_CLASS);
    }
  };

  onCardClickHandler = e => {
    this.cards.forEach(card => card.classList.remove(ACTIVE_CLASS));
    this.contentWrappers.forEach(el => el.classList.remove(ACTIVE_CLASS));

    const clickedCard = e.target;
    const serviceNameOfCard = clickedCard.getAttribute('data-service');
    const contentWithServiceName = document.querySelector(
      `.content__copy[data-service=${serviceNameOfCard}]`
    );

    contentWithServiceName.classList.add(ACTIVE_CLASS);
    clickedCard.classList.add(ACTIVE_CLASS);
  };
}
