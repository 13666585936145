import { Controller } from 'stimulus';
import { gsap, Power0, Power2 } from 'gsap/all';
import is from 'is_js';
import { Swipeable, remToPx } from 'shared/lib';
import debounce from 'lodash-es/debounce';
import throttle from 'lodash-es/throttle';

const smallestAnimationIncrement = 0.00000000000001;
const startingAnimationProgress = 15000 * smallestAnimationIncrement;
const smallestDragIncrement = 4000 * smallestAnimationIncrement;

export default class extends Controller {
  static targets = ['swipeContainer', 'filterContainer', 'filter', 'tile'];

  state = {
    isHovered: false,
    isDragging: false,
    lastDragPosition: null,
    dragMomentum: 0,
    activeFilter: 0,
  };

  connect() {
    this.tl = gsap.timeline();
    const observer = new IntersectionObserver(this.setup);
    observer.observe(this.element);
  }

  disconnect() {
    this.removeListeners();
  }

  setup = (entries, observer) => {
    const isIntersecting = !!entries.find(entry => entry.isIntersecting);

    if (!isIntersecting) {
      return;
    }

    observer.unobserve(this.element);

    this.disableFilteredTechs();
    this.filterTargets.forEach(filter => {
      filter.innerHTML = filter.innerHTML.split(' ').join('&nbsp;');
    });
    if (!is.mobile()) {
      this.startAnimation();
      this.setupForDesktop();
      this.addListeners();
    } else {
      this.setupForMobile();
    }
  };

  addListeners = () => {
    this.swipeContainerTarget.addEventListener('wheel', this.scrollTiles);
    this.swipeContainerTarget.addEventListener('mouseenter', this.debouncedMouseOver);
    this.swipeContainerTarget.addEventListener('mouseleave', this.disableMouseWheel);
    this.swipeContainerTarget.addEventListener('mousedown', this.onDragStart);
    document.addEventListener('mouseup', this.onDragStop);
    document.addEventListener('mousemove', this.throttledOnDrag);
  };

  removeListeners = () => {
    this.swipeContainerTarget.removeEventListener('wheel', this.scrollTiles);
    this.swipeContainerTarget.removeEventListener('mouseenter', this.debouncedMouseOver);
    this.swipeContainerTarget.removeEventListener('mouseleave', this.disableMouseWheel);
    this.swipeContainerTarget.removeEventListener('mousedown', this.onDragStart);
    document.removeEventListener('mouseup', this.onDragStop);
    document.removeEventListener('mousemove', this.throttledOnDrag);
  };

  onDragStart = e => {
    clearInterval(this.interval);

    this.setState({
      isDragging: true,
      lastDragPosition: e.x,
      dragMomentum: 0,
    });
  };

  onDragStop = () => {
    if (!this.state.isDragging) {
      return;
    }
    this.setState({ isDragging: false });
    this.setInertiaMove();
  };

  onDrag = e => {
    if (!this.state.isDragging) {
      return;
    }
    const dragDistance = this.state.lastDragPosition - e.clientX;
    this.setState({ lastDragPosition: e.clientX, dragMomentum: dragDistance });

    const normalizedDragDistance = this.normalizeDragDistance(dragDistance);
    this.moveCarousel(normalizedDragDistance);
  };

  setInertiaMove = () => {
    clearInterval(this.interval);
    this.interval = setInterval(this.scrollTilesByInertia, 10);
  };

  scrollTilesByInertia = () => {
    if (!this.state.isHovered) {
      this.setState({ dragMomentum: 0, lastDragPosition: null });
      clearInterval(this.interval);
    }
    const momentumDistance = this.normalizeDragDistance(this.state.dragMomentum);

    this.setState({ dragMomentum: this.state.dragMomentum * 0.95 });
    this.moveCarousel(momentumDistance);
  };

  scrollTiles = e => {
    if (!this.state.isHovered) {
      return;
    }
    e.preventDefault();

    const scrollDistance =
      smallestAnimationIncrement * (Math.abs(e.deltaX) > Math.abs(e.deltaY) ? e.deltaX : e.deltaY);
    this.moveCarousel(scrollDistance);
  };

  moveCarousel = distance => {
    const currentProgress = this.tl.progress();

    this.tl.progress(
      currentProgress +
        (currentProgress < startingAnimationProgress
          ? startingAnimationProgress + distance
          : distance)
    );
  };

  normalizeDragDistance = distance => (distance / window.innerWidth) * smallestDragIncrement;

  setupForMobile = () => {
    this.tilesX = 0;
    this.filtersX = 0;
    this.filterWidths = [];

    this.filteredTiles = this.tileTargets.filter(tile => !tile.classList.contains('disabled'));

    this.filteredTiles[this.tilesX].classList.add('active');

    this.setSwipeContainerWidth();
    this.swipeableTiles = new Swipeable(this.swipeContainerTarget, {
      onSwipedLeft: this.onTileSwipedLeft,
      onSwipedRight: this.onTileSwipedRight,
      maximumVerticalMove: 100,
    });
    this.swipeableFilters = new Swipeable(this.filterContainerTarget, {
      onSwipedLeft: this.onFilterSwipedLeft,
      onSwipedRight: this.onFilterSwipedRight,
    });
  };

  setSwipeContainerWidth = () => {
    this.swipeContainerTarget.style.width = `${this.filteredTiles.length * 100}%`;
  };

  onFilterSwipedLeft = () => {
    if (this.filtersX < this.filterTargets.length - 1) {
      this.filtersX++;
    }
    this.setFilterPosition();
  };

  onFilterSwipedRight = () => {
    if (this.filtersX > 0) {
      this.filtersX--;
    }
    this.setFilterPosition();
  };

  setFilterPosition = () => {
    const offset = [...Array(this.filtersX).keys()].reduce(
      (acc, key) => acc + this.filterTargets[key].getBoundingClientRect().width + remToPx(3),
      0
    );

    gsap.to(this.filterContainerTarget, {
      x: -offset,
      ease: Power2.easeInOut,
      duration: 0.6,
    });
  };

  onTileSwipedLeft = () => {
    if (this.tilesX < this.filteredTiles.length - 1) {
      this.switchActiveTile(1);
    }
    this.setTilePosition();
  };

  onTileSwipedRight = () => {
    if (this.tilesX > 0) {
      this.switchActiveTile(-1);
    }
    this.setTilePosition();
  };

  switchActiveTile = dir => {
    this.filteredTiles[this.tilesX].classList.remove('active');
    this.tilesX += dir > 0 ? 1 : -1;
    this.filteredTiles[this.tilesX].classList.add('active');
  };

  setTilePosition = () => {
    const { tilesX, tileTargets, swipeContainerTarget } = this;
    const tileMargin = window.innerWidth * 0.08;
    gsap.to(swipeContainerTarget, {
      x: -(tilesX * (tileTargets[0].getBoundingClientRect().width + tileMargin)),
      ease: Power2.easeInOut,
      duration: 0.6,
    });
  };

  setupForDesktop() {
    this.element.classList.add('desktop');
    this.tileTargets.forEach(tile => tile.classList.add('desktop'));
    this.filterTargets.forEach(filter => filter.classList.add('desktop'));
    this.swipeContainerTarget.classList.add('desktop');
    this.filterContainerTarget.classList.add('desktop');
  }

  startAnimation() {
    const currentFilterPhrase = this.filterTargets[this.state.activeFilter].dataset.filter;
    const filteredTiles = this.tileTargets.filter(
      tile => tile.dataset.filter === currentFilterPhrase
    );
    this.tl
      .staggerFromTo(
        filteredTiles,
        filteredTiles.length * 6,
        { x: filteredTiles.length * 50, y: 0 },
        { x: -filteredTiles.length * 325, y: 0, ease: Power0.easeNone, repeat: -1 },
        6
      )
      .progress(startingAnimationProgress);
  }

  restartAnimation() {
    this.tl.kill();
    this.tl = gsap.timeline();
    this.startAnimation();
  }

  disableFilteredTechs() {
    this.tileTargets.forEach(tile => {
      if (tile.dataset.filter === this.filterTargets[this.state.activeFilter].dataset.filter) {
        tile.classList.remove('disabled');
      } else {
        tile.classList.add('disabled');
      }
    });
    this.filteredTiles = this.tileTargets.filter(tile => !tile.classList.contains('disabled'));
  }

  setActiveFilterClass() {
    this.filterTargets.forEach((filter, index) => {
      if (index === this.state.activeFilter) {
        filter.classList.add('active');
      } else {
        filter.classList.remove('active');
      }
    });
  }

  onFilterClick(e) {
    if (this.state.activeFilter === this.filterTargets.indexOf(e.target)) {
      return;
    }
    this.setState({ activeFilter: this.filterTargets.indexOf(e.target) });

    this.tilesX = 0;

    this.setTilePosition();
    this.setActiveFilterClass();
    this.disableFilteredTechs();

    if (!is.mobile()) {
      this.restartAnimation();
    } else {
      this.setSwipeContainerWidth();
      this.filtersX = this.state.activeFilter;
      this.setFilterPosition();
    }
  }

  onMouseOver() {
    this.tl.pause();
  }

  onMouseOut() {
    this.tl.play();
  }

  enableMouseWheel = () => {
    this.setState({ isHovered: true });
  };

  disableMouseWheel = () => {
    this.state.isHovered = false;
    this.setState({ dragMomentum: 0, lastDragPosition: null, isHovered: false });
  };

  setState = newState => {
    this.state = { ...this.state, ...newState };
  };

  debouncedMouseOver = debounce(this.enableMouseWheel, 1000);

  throttledOnDrag = throttle(this.onDrag, 10);
}
