import CustomSelect from 'custom-select';
import { gsap, Power2 } from 'gsap';

import FormController from 'shared/form_controller';
import { addFacebookPixelCustomEvent, appendUtmToQueryParam } from 'shared/lib';

export default class extends FormController {
  static targets = [
    'name',
    'email',
    'heardAbout',
    'content',
    'textWrapper',
    'form',
    'confirmation',
    'confirmationLoader',
    'file',
    'submitButton',
    'captcha',
    'agreement',
  ];

  static values = { showHearAbout: Boolean, default: false };

  initialize() {
    const showHeardAboutAttribute = this.data.get('showHeardAbout');

    if (!this.isUndefinedOrNull(showHeardAboutAttribute)) {
      this.showHearAboutValue = showHeardAboutAttribute;
    } else {
      this.showHearAboutValue = false;
    }
  }

  connect() {
    if (this.showHearAboutValue) {
      CustomSelect('#contact-form-dropdown');
      this.setDropdownStyles();
    }
    appendUtmToQueryParam();

    window.hcaptcha.render('captcha-contact_form', {
      sitekey: this.data.get('siteKey'),
      theme: 'light',
    });
  }

  disconnect() {
    this.removeEventListeners();
  }

  isUndefinedOrNull = value => typeof value === 'undefined' || value === null;

  addEventListeners = () =>
    this.heardAboutSelect.addEventListener('change', this.resetDropdownStyles);

  removeEventListeners = () =>
    this.heardAboutSelect.removeEventListener('change', this.resetDropdownStyles);

  hideMockOption = () => {
    Array.from(this.heardAboutTarget.querySelectorAll('.custom-select-option')).find(
      option => option.dataset.value === ''
    ).style.display = 'none';
  };

  resetDropdownStyles = event => {
    if (event.target.value === '') {
      this.heardAboutTarget
        .querySelector('.custom-select-opener > span')
        .classList.add('is-disabled');
    } else {
      this.heardAboutTarget
        .querySelector('.custom-select-opener > span')
        .classList.remove('is-disabled');
      this.removeErrorMessage(this.heardAboutTarget);
    }
  };

  setDropdownStyles = () => {
    this.heardAboutSelect = this.heardAboutTarget.querySelector('select');
    this.addEventListeners();

    if (this.heardAboutSelect.value === '') {
      this.heardAboutTarget
        .querySelector('.custom-select-opener > span')
        .classList.add('is-disabled');
    }
    this.hideMockOption();
  };

  submit = event => {
    event.preventDefault();

    const isEmailValid = this.validateEmailInput(null, this.emailTarget);
    const isNameValid = this.validateInput(this.nameTarget, name => name !== '', 'fieldRequired');
    const isMessageValid = this.validateInput(
      this.contentTarget,
      content => content !== '',
      'fieldRequired'
    );
    const isCheckboxChecked = this.validateCheckbox(this.agreementTarget, '');

    const token = window.hcaptcha.getResponse();
    const isCaptchaSolved = this.validateCaptcha(this.captchaTarget, token.length !== 0);

    if (
      !isEmailValid ||
      !isNameValid ||
      !isMessageValid ||
      !isCaptchaSolved ||
      !isCheckboxChecked
    ) {
      return;
    }

    const formData = new FormData();

    formData.append('name', this.nameTarget.querySelector('input').value);
    formData.append('email', this.emailTarget.querySelector('input').value);
    if (this.showHearAboutValue) {
      formData.append('heard_about', this.heardAboutTarget.querySelector('select').value);
    }
    formData.append('content', this.contentTarget.querySelector('textarea').value);
    formData.append('h-captcha-response', token);
    formData.append('terms', this.agreementTarget.querySelector('input').checked);

    const fileInput = this.fileTarget.querySelector('input');
    if (fileInput.files && fileInput.files.length) {
      formData.append('attachment', fileInput.files[0]);
    }

    const onSuccess = () => {
      this.textWrapperTarget.classList.add('invisible');
      this.formTarget.classList.add('invisible');
      this.element.classList.add('message-sent');
      this.confirmationTarget.classList.add('visible');
      this.confirmationLoaderTarget.classList.remove('visible');
      gsap.to(window, {
        duration: 1,
        ease: Power2.easeInOut,
      });
      this.handleDataLayer('Kontakt');
      addFacebookPixelCustomEvent('ContactForm');
    };

    const onFail = () => {
      this.confirmationLoaderTarget.classList.remove('visible');
    };

    this.confirmationLoaderTarget.classList.add('visible');

    this.submitForm('/api/messages', formData, { onSuccess, onFail });
  };
}
