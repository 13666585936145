import FormController from 'shared/form_controller';

import { gsap, Power4 } from 'gsap/all';
import dayjs from 'dayjs';
import { range } from 'shared/lib';

const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;
const SECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
const SECONDS_IN_DAY = MINUTES_IN_DAY * SECONDS_IN_MINUTE;

const dayMilisecs = 24 * 60 * 60 * 1000;
const hourMilisecs = 60 * 60 * 1000;
const minuteMilisecs = 60 * 1000;

export default class extends FormController {
  static targets = ['clock', 'date', 'email', 'form', 'meetingButton'];

  currentTime = [];

  end = null;

  isCountdownActive = true;

  connect() {
    const observer = new IntersectionObserver(this.setup);
    observer.observe(this.element);
    this.setMockMVPDate();
    // eslint-disable-next-line
    this.locale = window.location.pathname.split('/')[1];
  }

  setMockMVPDate = () => {
    this.end = new Date(2019, 4, 13);

    const { end } = this;

    while (end < new Date()) {
      this.incrementDate();
    }
  };

  incrementDate = () => {
    const { end } = this;
    const month = end.getMonth();

    if (month === 11) {
      end.setMonth(0);
      end.setFullYear(end.getFullYear() + 1);
    } else {
      end.setMonth(month + 1);
    }
  };

  get timeToMockMVP() {
    const { end } = this;
    let diff = end - new Date();
    /* eslint-disable */
    const days = parseInt(diff / dayMilisecs);
    diff -= days * dayMilisecs;

    const hours = parseInt(diff / hourMilisecs);
    diff -= hours * hourMilisecs;

    const minutes = parseInt(diff / minuteMilisecs);
    diff -= minutes * minuteMilisecs;

    const seconds = parseInt(diff / 1000);
    return [days, hours, minutes, seconds];
    /* eslint-enable */
  }

  setup = (entries, observer) => {
    const isIntersecting = !!entries.find(entry => entry.isIntersecting);

    if (!isIntersecting) {
      return;
    }

    observer.unobserve(this.element);
    this.displayEventDate();
    this.initializeTweens();
    this.initializeClock();
    if (this.isCountdownActive) {
      setInterval(this.updateClock, 1000);
    }
  };

  get dateString() {
    const { start, end } = this.dateTarget.dataset;

    const startDate = dayjs(start);
    const endDate = end ? dayjs(end) : null;

    return end
      ? `${startDate.format('MMMM D')} - ${endDate.format(
          startDate.month() === endDate.month() ? 'D' : 'MMMM D'
        )}`
      : startDate.format('MMMM D');
  }

  get timeToEvent() {
    const eventStartDate = dayjs(this.dateTarget.dataset.start);
    const today = dayjs();

    if (!today.isBefore(eventStartDate)) {
      this.isCountdownActive = false;
      return [0, 0, 0, 0];
    }
    const days = Math.floor(eventStartDate.diff(today, 'days'));
    const hours = Math.floor(eventStartDate.diff(today, 'hours') - days * HOURS_IN_DAY);
    const minutes = Math.floor(
      eventStartDate.diff(today, 'minutes') - days * MINUTES_IN_DAY - hours * MINUTES_IN_HOUR
    );
    const seconds = Math.floor(
      eventStartDate.diff(today, 'seconds') -
        days * SECONDS_IN_DAY -
        hours * SECONDS_IN_HOUR -
        minutes * SECONDS_IN_MINUTE
    );

    return [days, hours, minutes, seconds];
  }

  displayEventDate() {
    this.dateTarget.textContent =
      this.dateTarget.dataset.displaydate === 'false' ? '' : this.dateString;
  }

  initializeTweens = () => {
    this.tweens = this.clockTargets.map(item => {
      const prevTimeLeftDigit = item.children[0].children[0];
      const prevTimeRightDigit = item.children[0].children[1];
      const currTimeLeftDigit = item.children[1].children[0];
      const currTimeRightDigit = item.children[1].children[1];

      return {
        left: gsap.fromTo(
          [prevTimeLeftDigit, currTimeLeftDigit],
          { y: '0%', duration: 0.8 },
          { y: '100%', ease: Power4.easeInOut }
        ),
        right: gsap.fromTo(
          [prevTimeRightDigit, currTimeRightDigit],
          { y: '0%', duration: 0.8 },
          { y: '100%', ease: Power4.easeInOut }
        ),
      };
    });
  };

  initializeClock = () => {
    this.currentTime =
      this.dateTarget.dataset.displaydate === 'false' ? this.timeToMockMVP : this.timeToEvent;
    this.currentTime.forEach((item, index) => {
      const currentValue = `0${item}`.slice(-2);
      const prevValue = currentValue;
      const currentTimerDigits = Array.from(this.clockTargets[index].children[0].children);
      const previousTimerDigits = Array.from(this.clockTargets[index].children[1].children);

      currentTimerDigits.forEach((digit, i) => {
        digit.textContent = currentValue[i];
      });
      previousTimerDigits.forEach((digit, i) => {
        digit.textContent = prevValue[i];
      });
    });
  };

  updateClock = () => {
    if (!this.isCountdownActive) {
      return;
    }
    const elementsToUpdate = range(4).map(() => ({ left: false, right: false }));
    const timeToUpdate =
      this.dateTarget.dataset.displaydate === 'false' ? this.timeToMockMVP : this.timeToEvent;
    timeToUpdate.forEach((newClockContentItem, index) => {
      const oldClockContentItem = this.currentTime[index];
      if (newClockContentItem !== oldClockContentItem) {
        const currClockItemValue = `0${oldClockContentItem}`.slice(-2);
        const prevClockItemValue = `0${newClockContentItem}`.slice(-2);
        const currentTimerDigits = Array.from(this.clockTargets[index].children[0].children);
        const previousTimerDigits = Array.from(this.clockTargets[index].children[1].children);

        currentTimerDigits.forEach((digit, i) => {
          digit.textContent = currClockItemValue[i];
        });
        previousTimerDigits.forEach((digit, i) => {
          digit.textContent = prevClockItemValue[i];
        });

        if (prevClockItemValue[0] !== currClockItemValue[0]) {
          elementsToUpdate[index].left = true;
        }
        if (prevClockItemValue[1] !== currClockItemValue[1]) {
          elementsToUpdate[index].right = true;
        }

        this.currentTime[index] = newClockContentItem;
      }
    });

    this.animateClock(elementsToUpdate);
  };

  animateClock = elementsToUpdate => {
    elementsToUpdate.forEach((value, index) => {
      if (value.left) {
        this.tweens[index].left.restart();
      }
      if (value.right) {
        this.tweens[index].right.restart();
      }
    });
  };

  redirectToCalendly = e => {
    e.preventDefault();

    if (!this.validateEmailInput(null, this.emailTarget)) {
      return;
    }

    const userEmail = this.emailTarget.querySelector('input').value;

    if (userEmail) {
      window.open(
        `https://calendly.com/d/dv3-tfw-97c/intro-call-with-startup-house?email=${userEmail}`,
        '_blank'
      );
    }
  };
}
